@mixin apply-bootstrap {
  /* This goal is to get rid of this file */
  .container,
  .container-fluid {
    display: block;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .col,
  .col-12,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-lg-10,
  .col-lg-12,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-8,
  .col-md-10,
  .col-md-12,
  .col-md-6,
  .col-md-8,
  .col-md-9,
  .col-sm-12,
  .col-sm-8,
  .col-xl-10,
  .col-xl-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 576px) {
    .col-sm-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (min-width: 768px) {
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-md-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-md-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .offset-md-1 {
      margin-left: 8.333333%;
    }
  }

  @media (min-width: 992px) {
    .col-lg-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .col-lg-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-lg-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .col-lg-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .offset-lg-1 {
      margin-left: 8.333333%;
    }

    .offset-lg-2 {
      margin-left: 16.666667%;
    }
  }

  @media (min-width: 1200px) {
    .col-xl-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .col-xl-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    .col-xl-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .col-xl-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
  }
}
