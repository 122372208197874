@use 'variables' as *;

@mixin adjust-components-to-preheader() {
  .pre-header-banner {
    .app-container {
      padding-top: $padding-top-app-container + $pre-header-height;
      @media screen and (min-width: calc(1024px - 1px)) {
        padding-top: $padding-top-app-container-mobile + $pre-header-height;
      }
    }

    .header-v3,
    .header-v3 .header-v3__wrapper .mobile-menu-burger,
    .phone-block {
      top: $pre-header-height;
    }
  }
}

@mixin app-container() {
  display: block;
  padding-top: 90px;
}

@mixin remove-scrollbar() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

$layout-gap-mobile: 24px;
$layout-gap-desktop: 30px;

@mixin layout() {
  display: grid;
  justify-content: center;
  min-height: 100%;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: $layout-gap-mobile;
  padding-inline: 2rem;

  @media screen and (min-width: 375px) {
    grid-template-columns: repeat(4, minmax(0, 65px));
    column-gap: $layout-gap-mobile;
    padding-inline: 0;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 140px));
    column-gap: $layout-gap-desktop;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(12, minmax(0, 65px));
    column-gap: $layout-gap-desktop;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: repeat(12, minmax(0, 65px));
    column-gap: $layout-gap-desktop;
  }
}
