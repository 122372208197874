.page-landing-solar {
  background: transparent;

  .app-container {
    padding-top: 0 !important;
  }

  nscf-header {
    nscf-header-cta-button,
    nscf-header-cta-button-connected {
      .effy-button {
        background-color: #fff !important;
      }
    }
  }

  .header-v3 {
    transition: background-color 0.5s ease;
    background: var(--header-background-solar-color, transparent) !important;
    box-shadow: none !important;

    &:has(#menu-mobile:checked) {
      transition: background-color 1s cubic-bezier(0.77, 0.2, 0.05, 1);
      background-color: var(--main-color) !important;
    }

    .line {
      transition: background-color 0.5s ease;
      background: white !important;
    }

    .logo {
      img {
        filter: brightness(0) invert(1);
      }
    }

    &:not(.mobile) {
      .header-link__link,
      .espace-pro {
        color: white !important;
      }
    }
  }

  ul {
    margin: unset;
    margin-block-start: unset;
    margin-block-end: unset;
    padding-inline-start: unset;
  }

  button {
    all: unset;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }

  .secondary-title {
    @apply font-family text-2xl md:text-4xl;
  }

  @mixin button-base {
    cursor: pointer;
    white-space: nowrap;
    text-transform: uppercase;
    border-radius: 30px;
    text-decoration: none;
    padding: 17px 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s cubic-bezier(0.4, 0.5, 0.51, 1);

    &:disabled {
      cursor: not-allowed;
      background-image: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  button.white-button,
  a.white-button {
    @include button-base();
    @apply font-700 text-sm text-primary-color bg-white hover:bg-grey-middle;
  }

  @keyframes going-up {
    from {
      opacity: 0;
      transform: translateY(calc(50vh - 80px));
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .block-go-up {
    opacity: 0;
    transform: translateY(calc(50vh - 80px));

    &.block-go-up-anim {
      animation: going-up 1.2s;
      animation-fill-mode: forwards;
    }
  }
}

.effy-theme.page-landing-solar {
  .header-v3 {
    nscf-logo {
      mat-icon {
        @apply text-shiny-yellow #{!important};
      }
    }
  }
}
