:root .effy-theme {
  --main-color: #161637;
  --main-color-rgb: 22, 22, 55;
  --on-main-color: #ffffff;
  --on-main-color-rgb: 255, 255, 255;
  --primary-color: #fff164;
  --primary-color-rgb: 255, 241, 100;
  --on-primary-color: #161637;
  --on-primary-color-rgb: 22, 22, 55;
  --primary-color-active: #eac91a;
  --primary-color-active-rgb: 234, 201, 26;
  --on-primary-color-active: #161637;
  --on-primary-color-active-rgb: 22, 22, 55;
  --secondary-color: #ff6b6b;
  --secondary-color-rgb: 255, 107, 107;
  --on-secondary-color: #161637;
  --on-secondary-color-rgb: 22, 22, 55;
  --tertiary-color: #4754ff;
  --tertiary-color-rgb: 71, 84, 255;
  --on-tertiary-color: #ffffff;
  --on-tertiary-color-rgb: 255, 255, 255;
  --accent-color: #4754ff;
  --accent-color-rgb: 71, 84, 255;
  --font-family: buenos-aires, Arial, sans-serif;
}

:root .pro-theme {
  --main-color: #161637;
  --main-color-rgb: 22, 22, 55;
  --on-main-color: #ffffff;
  --on-main-color-rgb: 255, 255, 255;
  --primary-color: #4754ff;
  --primary-color-rgb: 71, 84, 255;
  --on-primary-color: #ffffff;
  --on-primary-color-rgb: 255, 255, 255;
  --primary-color-active: #1725c7;
  --primary-color-active-rgb: 23, 37, 199;
  --on-primary-color-active: #ffffff;
  --on-primary-color-active-rgb: 255, 255, 255;
  --secondary-color: #66a8ff;
  --secondary-color-rgb: 102, 168, 255;
  --on-secondary-color: #161637;
  --on-secondary-color-rgb: 22, 22, 55;
  --tertiary-color: #4754ff;
  --tertiary-color-rgb: 71, 84, 255;
  --on-tertiary-color: #ffffff;
  --on-tertiary-color-rgb: 255, 255, 255;
  --accent-color: #4754ff;
  --accent-color-rgb: 71, 84, 255;
  --font-family: buenos-aires, Arial, sans-serif;
}