/*! purgecss start ignore */
@use 'main' as *;
@use 'style-paths/landing-solar-common' as *;
@use '../../../acquisition-style/scss/global-common.scss' as gc;

@layer tailwindBase, common, designSystem;

@layer common {
  @include gc.apply-global-common;
}

@layer tailwindBase {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

:root {
  --header-v3-logo-max-width: 120px;
  --rte-list-checkmark-background-url: 'assets/checkmark/checkmark-effy.svg';
  @include adjust-components-to-preheader();
}
/*! purgecss end ignore */
