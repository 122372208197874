@mixin apply-default {

/*! purgecss start ignore */
svg {
  overflow: hidden;
}

html,
body {
  background-color: var(--tofu-white);
}

body {
  @apply font-family text-main-color m-0;
}

@-moz-document url-prefix() {
  body {
    .header-v3__logo {
      max-width: var(--header-v3-logo-max-width, 250px);
    }
  }
}

/*
Classes generated and elem created by Drupal.
Here its class generated by drupal this have
to be more powerfull like the css of the project so important here can't be delete.
*/
.align-left {
  float: left;
  margin-bottom: 20px;
  margin-right: 30px;
}

.align-center {
  display: flex;
  justify-content: center;
}

.align-right {
  &.embedded-entity {
    float: right;
    margin-bottom: 20px;
    margin-left: 30px;
  }
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

// --------------------------------------------------------
/*
Styles common multi componant start
*/

.text-image,
.text-video,
.text-table {
  p {
    margin: 0;
  }

  .legend {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0;
    padding-left: 10px;
    position: relative;
    text-align: left;

    p {
      @apply text-md;
      margin: 0;
    }

    &::before {
      background-color: var(--primary-color);
      background-size: 100% auto;
      border-radius: 10px;
      content: '';
      left: 0;
      margin-right: 10px;
      min-height: 100%;
      position: absolute;
      top: 0;
      width: 3px;
    }
  }
}

.medias,
.faq {
  .h2-title {
    margin-bottom: 20px;
  }

  &__chapo {
    .text__content > p {
      margin-bottom: 32px;
    }
  }
}

/* Page Travaux */
.pagetype-pagetravaux {
  .text--article {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

/* Page Article */
.pagetype-pagearticle {
  .article-breadcrumb {
    .col-md-8,
    .col-lg-8 {
      // Its an surchage of bootstrap so i disable the rules vendor here only
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media screen and (min-width: 992px) {
    .cardification__wrapper-card {
      margin-right: 15px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .cardification__wrapper-card {
      margin-right: 30px !important;
    }
  }
}

// --------------------------------------------------------

/*
InnerHtml can't be touch on componant so
need to be style on style.scss after rendering element
*/
.phone-block {
  &__text-part {
    .paragraph {
      p {
        margin: 0;
      }
    }
  }
}

/*! purgecss end ignore */
}