// Breakpoints bootstrap
$b-grid-breakpoints-xs: 0;
$b-grid-breakpoints-sm: 576px;
$b-grid-breakpoints-md: 768px;
$b-grid-breakpoints-lg: 992px;
$b-grid-breakpoints-xl: 1200px;

// Breakpoints effy
$grid-breakpoints-mobile-se: 340px;
$grid-breakpoints-mobile: 640px;
$grid-breakpoints-desktop: 1024px;

// Box sizes
$header-height: 80px;
$mobile-header-height: 60px;
$pre-header-height: 40px;
$padding-top-app-container: 90px;
$padding-top-app-container-mobile: 160px;

/*
Box-Shadow
*/
$shadow-1: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$shadow-2: 0 10px 12px 0 rgba(0, 0, 0, 0.25);
$shadow-4: 0 0 10px 4px rgba(2, 126, 124, 0.3);
$shadow-6: 0 10px 50px 0 rgba(0, 0, 0, 0.05);
$shadow-9: 0 4px 20px 0 rgba(0, 0, 0, 0.1); //$box-shadow-nhp
